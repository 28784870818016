import React, { useEffect } from "react";
import Head from "next/head";

import { ApolloClient, ApolloProvider, ApolloLink, HttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "apollo-link-error";
import Router from "next/router";

const { createUploadLink } = require("apollo-upload-client");

import fetch from "isomorphic-unfetch";
import setCookie from "set-cookie-parser";

let globalApolloClient = null;
const GRAPHQL_URL = process.env.NEXT_PUBLIC_API_URL;

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export function withApollo(PageComponent, { ssr = true } = {}) {
    const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
        //console.log(apolloClient, typeof window, PageComponent);
        const client = apolloClient || initApolloClient(apolloState);

        // useEffect(() => {
        //     client.disableNetworkFetches = true;
        // }, [])
        //console.log(JSON.stringify(apolloState));
        return (
            <ApolloProvider client={client}>
                <PageComponent {...pageProps} client={client} />
            </ApolloProvider>
        );
    };

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== "production") {
        const displayName = PageComponent.displayName || PageComponent.name || "Component";

        if (displayName === "App") {
            console.warn("This withApollo HOC only works with PageComponents.");
        }

        WithApollo.displayName = `withApollo(${displayName})`;
    }

    if (ssr || PageComponent.getInitialProps) {
        WithApollo.getInitialProps = async (ctx) => {
            //console.log("RUNNING");
            const { AppTree, req, res } = ctx;

            // Initialize ApolloClient, add it to the ctx object so
            // we can use it in `PageComponent.getInitialProp`.
            const apolloClient = (ctx.apolloClient = initApolloClient({}, req, res));

            // Run wrapped getInitialProps methods
            let pageProps = {};
            if (PageComponent.getInitialProps) {
                pageProps = await PageComponent.getInitialProps(ctx);
            }

            // Only on the server:
            if (typeof window === "undefined") {
                // When redirecting, the response is finished.
                // No point in continuing to render
                if (ctx.res && ctx.res.finished) {
                    return pageProps;
                }

                // Only if ssr is enabled
                if (ssr) {
                    try {
                        // Run all GraphQL queries
                        const { getDataFromTree } = await import("@apollo/client/react/ssr");
                        await getDataFromTree(
                            <AppTree
                                pageProps={{
                                    ...pageProps,
                                    apolloClient,
                                }}
                            />
                        );
                    } catch (error) {
                        console.error("Error while running `getDataFromTree`", error);
                        //console.log("AAAAAAA");
                        const { origin } = absoluteUrl(req);
                        const fullUrl = origin + ctx.asPath;
                        res.writeHead(302, { Location: `/login?redirect_to=${encodeURIComponent(fullUrl)}` });
                        res.end();
                        // Prevent Apollo Client GraphQL errors from crashing SSR.
                        // Handle them in components via the data.error prop:
                        // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
                    }

                    // getDataFromTree does not call componentWillUnmount
                    // head side effect therefore need to be cleared manually
                    Head.rewind();
                }
            }

            // Extract query data from the Apollo store
            const apolloState = apolloClient.cache.extract();

            // Now we pass back any props generated from the components getInitialProps (if any) along with the
            // apollo state generated by running every query in the App Tree (in SSR mode). We can use all of this
            // data to render.
            return {
                ...pageProps,
                apolloState,
            };
        };
    }

    return WithApollo;
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
function initApolloClient(initialState = {}, req, res) {
    // Make sure to create a new client for every server-side request so that data
    // isn't shared between connections (which would be bad)
    if (typeof window === "undefined") {
        return createApolloClient(initialState, req, res);
    }

    // Reuse client on the client-side
    if (!globalApolloClient) {
        globalApolloClient = createApolloClient(initialState, req, res);
    }

    return globalApolloClient;
}

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
function createApolloClient(initialState = {}, req, res) {
    const httpLinkOptions = {
        uri: GRAPHQL_URL, // Server URL (must be absolute)
        credentials: "include", // Additional fetch() options like `credentials` or `headers`
        fetch: createCustomFetch(res),
        headers: {},
    };

    //console.log("CREATING");
    console.log(req?.headers?.cookie);

    // We must only ever set custom headers on the server side which gives zero f***s about pre-flight
    // request responses. This is fine since this header is only ever needed on the server. To summarise
    // its point, it pretty much says to the server "please do your usual auth checks but if my access
    // token is out of date (and my refresh token is valid) not renew/rollover. This means NextJs won't
    // cause any new sessions/refresh tokens to be generated so the client won't accidently present an
    // outdated token causing the server to think it stole it and log it out". Eventually I would like
    // to remove this since we already have the ability to propogate Set-Cookie headers back to the client
    // using our custom fetch helper. The one thing we are missing is the ability to update subsequent
    // requests made on the server with any returned headers (which is a little finicky). I put in a very
    // small threshold for outdated by one refresh tokens on the server so this mayy not be necessary, but
    // regardless more investigation is needed.
    if (req?.headers?.cookie) {
        httpLinkOptions.headers = {
            cookie: req.headers.cookie,
            "Disable-Refresh-Rollover": "true",
        };
    }

    const errorLink = onError(({ graphQLErrors, operation, forward, response }) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                switch (err.message) {
                    case "unauthenticated":
                        console.log(response);
                        if (res) {
                            //res.writeHead(302, { Location: `/login?reason=hyperactive-auth` });
                        } else if (typeof window !== "undefined") {
                            //console.log(res, response, req);
                            Router.push(`/login?reason=hyperactive-auth`, `/login?reason=hyperactive-auth`);
                        }
                }
            }
        }
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 2,
            retryIf: (error, operation) => {
                return error?.message === "unauthenticated";
            },
        },
    });

    const link = ApolloLink.from([retryLink, errorLink, createUploadLink(httpLinkOptions)]);

    // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
    return new ApolloClient({
        ssrMode: typeof window === "undefined", // Disables forceFetch on the server (so queries are only run once)
        link,
        cache: new InMemoryCache().restore(initialState),
        ssrForceFetchDelay: 100,
    });
}

/**
 * This will propogate any Set-Cookie headers from the server back to the client using the response
 * object. At the moment it's main purpose is to propogate any session updates or refresh token
 * deletions since "Disable-Refresh-Rollover" tells the server not to perform any updates on these
 * cookies outside of that.
 * @param {*} res The response object (only available on the server)
 */
function createCustomFetch(res) {
    return (url, options) => {
        return fetch(url, options)
            .then(async (raw) => {
                // const json = await raw.json();
                // console.log("RAW ", json);

                // if (json?.errors?.[0]?.message === "unauthenticated") {
                //     if (res) {
                //         res.writeHead(302, { Location: `/login?reason=hyperactive-auth` });
                //     } else if (typeof window !== "undefined") {
                //         //console.log(res, response, req);
                //         Router.push(`/login?reason=hyperactive-auth`, `/login?reason=hyperactive-auth`);
                //     }
                // } else
                if (res && !res.headerSent) {
                    /**
                     * The fetch headers object cannot represent more than one header so their solution was
                     * to combine them in one header since fetch is meant for the client side. This means we
                     * need to split them up and set each one on the response individually
                     *
                     * https://fetch.spec.whatwg.org/#headers-class
                     * https://github.com/whatwg/fetch/issues/506#issuecomment-395756703
                     */
                    console.log(raw.headers);
                    const combinedCookieHeader = raw.headers.get("Set-Cookie");
                    const splitCookieHeaders = setCookie.splitCookiesString(combinedCookieHeader);
                    const cookies = setCookie.parse(splitCookieHeaders, {
                        domain: process.env.NEXT_PUBLIC_ROOT_DOMAIN,
                    });

                    //console.log("COOKIES ", cookies);

                    for (let cookie of splitCookieHeaders) {
                        res.setHeader("set-Cookie", cookie);
                    }
                }

                return raw;
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

function absoluteUrl(req, localhostAddress = "localhost:3000") {
    let host = (req?.headers ? req.headers.host : window.location.host) || localhostAddress;
    let protocol = /.*localhost(:\d+)?$/.test(host) ? "http:" : "https:";

    if (req && req.headers["x-forwarded-host"] && typeof req.headers["x-forwarded-host"] === "string") {
        host = req.headers["x-forwarded-host"];
    }

    if (req && req.headers["x-forwarded-proto"] && typeof req.headers["x-forwarded-proto"] === "string") {
        protocol = `${req.headers["x-forwarded-proto"]}:`;
    }

    return {
        protocol,
        host,
        origin: protocol + "//" + host,
    };
}
